import Box from '@spraoi/base/Box';
import Button from '@spraoi/base/Button';
import React from 'react';
import { Link } from 'gatsby';
import ArrowLink from '../../components/ArrowLink';
import ContactCTA from '../../components/ContactCTA';
import Icon from '../../components/Icon';
import IconFigure from '../../components/IconFigure';
import SEO from '../../components/SEO';
import Section from '../../components/Section';
import useArticles from '../../effects/use-articles';

import warehouse from '../../images/icons/warehouse.png';
import manufacturing from '../../images/icons/manufacturing.png';
import logistics from '../../images/icons/logistics.png';

const Index = () => {
  const articles = useArticles();
  const latestAnnouncement = articles.find((a) => a.frontmatter.isAnnouncement);

  return (
    <>
      <SEO
        description="Zemfyre is accelerating the path to Industry 4.0 by enabling seamless Ethernet to the field."
        title="Zemfyre | Secure IIoT Communications."
      />
      <Section
        sx={{
          display: [null, null, null, 'flex'],
          justifyContent: 'space-between',
          position: 'relative',
          textAlign: [null, null, 'center', 'left'],
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <Icon
            svg="logo-background"
            sx={{
              left: ['-19rem', null, '-6rem', '-19rem'],
              opacity: 0.2,
              position: 'absolute',
              top: '-17rem',
              width: '50rem',
              zIndex: -1,
            }}
          />
          <Box
            as="h1"
            sx={{
              fontSize: '2.6rem',
              lineHeight: 1,
              maxWidth: 'maxWidths.paragraphWider',
              my: 5,
            }}
          >
          Secure Industrial
          <br></br>
          Communications
          </Box>
          <Box
            as="p"
            sx={{
              fontSize: 4,
              lineHeight: 1,
              maxWidth: 'maxWidths.paragraphWider',
              my: 5,
            }}
          >
            Accelerating Industry 4.0 through seamless secure Ethernet IIoT connectivity. 
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', null, 'row'],
              justifyContent: ['center', null, null, 'flex-start'],
              pt: 2,
            }}
          >
            

            <Button
              link="/contact/"
              sx={{ maxWidth: [null, null, 'auto'], mt: [2, null, 0] }}
            >
              Let&rsquo;s Talk
            </Button>
          </Box>
          
        </Box>
        <Box
          as="ul"
          sx={{
            display: 'flex',
            flexShrink: 0,
            flexWrap: 'wrap',
            justifyContent: [null, null, 'center', 'flex-start'],
            ml: ['-0.5rem', null, 0, 6],
            mr: [null, null, null, '-1.25rem'],
            mt: [null, null, null, '0.rem'],
            pt: [7, null, null, 0],
            width: [null, null, null, '22.5rem'],
          }}
        >
          <IconFigure
            as="li"
            iconWidth="9.5rem"
            png={manufacturing}
            sx={{
              m: [3, null, 4],
              top: [null, null, null, '8rem'],
            }}
          />
          <IconFigure
            as="li"
            iconWidth="9.5rem"
            png={warehouse}
            sx={{
              m: [3, null, 4],
              position: 'relative',
              top: [null, null, null, '5rem'],
            }}
          />
          <IconFigure
            as="li"
            iconWidth="9.5rem"
            png={logistics}
            sx={{ m: [3, null, 4] }}
          />
        </Box>
      </Section>
      <Section sx={{ pt: 0, px: [0, null, 7] }}>
        <Box
          sx={{
            alignItems: 'center',
            bg: 'white',
            borderRadius: [null, null, 2],
            boxShadow: 3,
            display: [null, null, null, 'flex'],
            justifyContent: 'space-between',
            mt: ['1px', null, 7],
            p: [6, null, null, 7],
          }}
        >
          <Icon
            svg="spe-sa"
            sx={{
              flexShrink: 0,
              mb: [3, null, null, 0],
              mr: [null, null, null, 7],
            }}
            width={['10rem', null, null, '13rem']}
          />
          <Box>
            <h4>Proud Member of the Single Pair Ethernet System Alliance</h4>
            <Box as="p" sx={{ color: 'text.subtle', lineHeight: 2, mt: 4 }}>
            The Single Pair Ethernet System Alliance is a worldwide association of leading technology companies. 
            We want to implement and further develop the pioneering Single Pair Ethernet technology. Together and holistically.
            </Box>
          </Box>
        </Box>
      </Section>
      
    </>
  );
};

export default Index;
